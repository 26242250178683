import axios from 'axios';
import process from 'process';

// let API_BASE_URL = process.env.NODE_ENV === 'production' ? '/api' : '/api';
let API_BASE_URL = 'api';

// 创建axios实例
const _axios = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});


// 请求拦截器
_axios.interceptors.request.use(
  (config) => {
    // 在发送请求前添加请求头
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    // 处理错误请求
    return Promise.reject(error);
  }
);

// 响应拦截器
_axios.interceptors.response.use();

export default _axios;
