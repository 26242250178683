import { SET_USERROLE,SET_ORGID,SET_USERNAME,SET_ORGNAME } from "../mutations";
import mutations from "../mutations";

const storeRole=localStorage.getItem('role')

const state = {
  role: JSON.parse(storeRole)|| [],
  orgId:localStorage.getItem('orgId')||"",
  username:localStorage.getItem('username')||"",
  orgName:localStorage.getItem('orgName')||"",
  serverName:"Canada"
};

const actions = {
  setRole({ commit }, role) {
    commit(SET_USERROLE, role);
  },
  setOrgId({commit},orgId){ 
    commit(SET_ORGID,orgId)
  },
  setUsername({commit},username){
    commit(SET_USERNAME,username)
  },
  setOrgName({commit},orgName){
    commit(SET_ORGNAME,orgName)
  }
};

const getters = {
  getRole: (state) => state.role,
  getOrgId:(state)=>state.orgId,
  getUsername:(state)=>state.username,
  getOrgName:(state)=>state.orgName
};

export default {
  state,
  mutations,
  actions,
  getters
};