<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted(){
    let hostStr=window.location.host
    let arr=hostStr.split('admin.')
    let currentArea=arr[0]
    console.log(currentArea);
    if(currentArea===""){
      //admin.pontosense.dev 跳转到ca-admin.pontosense.dev
      if(hostStr.includes('dev')){//UAT环境
        let host='ca-admin.pontosense.dev'
        window.location.href=`https://${host}`
      }else if(hostStr.includes('cloud')){
        let host='ca-admin.pontosense.cloud'
        window.location.href=`https://${host}`
      }
    }
  }
};
</script>

<style  lang="scss">
@font-face {
  font-family: 'DINAlternate';
  src: url('./assets/font/DIN.ttf');
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  min-width: 1280px;
  width: 100vw;
  height: 100vh;
  font-family: PingFang SC, Helvetica, sans-serif;
}

// home switch
.el-dropdown-menu.el-popper {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 10px 10px 0 rgba(85, 88, 181, 0.1);
  border: solid 1px #eaeaf2;
  background-color: #fff;

  .popper__arrow {
    display: none;
  }

  .el-dropdown-menu__item {
    height: 40px;
    border-radius: 5px;
    color: #222e61;
    font-size: 14px;
    padding: 0 40px 0 10px;


    >.server-item{
      display: flex;
      align-items: center;
      >.ic-flag{
        display: flex;
        align-items: center;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        >img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .el-dropdown-menu__item:hover {
    background-color: rgba(121, 130, 166, 0.15);
    color: #222e61;

  }

  .current {
    position: relative;
    color: #325bf2;
    font-size: 14px;
    font-weight: 500;
  }

  .current::after {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    content: "";
    width: 15px;
    height: 15px;
    // background: url("./assets/icon/ic_current_home.png") no-repeat center;
    background-size: contain;
  }

  .current:hover {
    color: #325bf2;

    .current::after {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      content: "";
      width: 15px;
      height: 15px;
      // background: url("./assets/icon/ic_current_home.png") no-repeat center;
      background-size: contain;
    }
  }
}

.sn-content{
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  .sn-item{
    margin: 3px 0;
  }
}
</style>