export const SET_USERROLE = "SET_USERROLE";
export const SET_ORGID = "SET_ORGID";
export const SET_USERNAME = "SET_USERNAME";
export const SET_ORGNAME = "SET_ORGNAME";
export const DELETE_USERNAME="DELETE_USERNAME";
export const DELETE_ORGID="DELETE_ORGID";
export const DELETE_ORGNAME="DELETE_ORGNAME";




const mutations = {
  [SET_USERROLE](state, role) {
    state.role = role;
  },
  [SET_ORGID](state, orgId) {
    state.orgId = orgId;
  },
  [SET_USERNAME](state, username) {
    state.username = username;
  },
  [SET_ORGNAME](state, orgName) {
    state.orgName = orgName;
  },
  [DELETE_USERNAME](state) {
    state.username = null; // 或者 state.username = '';，根据需求选择
  },
  [DELETE_ORGID](state) {
    state.orgId = null;
  },
  [DELETE_ORGNAME](state) {
    state.orgName = null;
  },
};

export default mutations;
