import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import Http from './services';

import toast from '@/components/toast/index';


Vue.use(Http);

Vue.use(ElementUI,{locale});

Vue.config.productionTip = false;

Vue.prototype.$toast = toast;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
