const whiteList = ["/login", "/"];
export function beforeRouterEach(to, from, next) {
  const token = localStorage.getItem("token");

  if (whiteList.includes(to.path)) {
    if (token) {
      next("/tenant");
    } else {
      next();
    }
  } else {
    if (token) {
      const toastBox = document.querySelector("#toast-box");
      if (toastBox) {
        while (toastBox.firstChild) {
          toastBox.removeChild(toastBox.firstChild);
        }
      }
      next();
    } else {
      next("/login");
    }
  }
}
