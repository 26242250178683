import Vue from 'vue';
import Toast from './index.vue';

const toast = (text = '',code='', parentEl = '#toast-box') => {
  const Construtor = Vue.extend(Toast);
  const instance = new Construtor({
    data: {
      text,
      code,
    }
  }).$mount();
  instance.visible = true;

  const pEl = document.querySelector(parentEl);
  pEl.appendChild(instance.$el);

};

export default toast;
