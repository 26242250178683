import Vue from "vue";
import Vuex from "vuex"
import user from "./modules/user"
import mutations from "./mutations";


Vue.use(Vuex)

// 导出vuex实例
export default new Vuex.Store({
    modules:{
        user
    },
    mutations
})
