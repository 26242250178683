const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: { public: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login/index.vue"),
    meta: { public: true },
  },
  {
    path:"/tenant",
    name:"tenant",
    component:()=>import("@/pages/tenant/index.vue"),
    redirect:"/tenant/accounts",
    children:[
      {
        path:"accounts",
        name:"accounts",
        component:()=>import("@/pages/tenant/account.vue")
      },
      {
        path:"logs",
        name:"logs",
        component:()=>import("@/pages/tenant/log.vue")
      },
      {
        path:"orgs",
        name:"orgs",
        component:()=>import("@/pages/tenant/org.vue")
      },
      {
        path:"users",
        name:"users",
        component:()=>import("@/pages/tenant/user.vue")
      }

    ]
  },
  {
    path:"/sensor",
    name:"sensor",
    component:()=>import("@/pages/sensor/index.vue"),
    redirect:"/sensor/sensors",
    children:[
      {
        path:"sensors",
        name:"sensors",
        component:()=>import("@/pages/sensor/sensors.vue")
      },
      {
        path:"firmware",
        name:"firmware",
        component:()=>import("@/pages/sensor/firmware.vue")
      },
      {
        path:"R&D",
        name:"R&D",
        component:()=>import("@/pages/sensor/rd.vue")
      }
    ]
  },
  {
    path:'/system',
    name:'system',
    component:()=>import("@/pages/system/index.vue"),
    redirect:"/system/upgrade",
    children:[
      {
        path:"upgrade",
        name:"upgrade",
        component:()=>import("@/pages/system/upgrade.vue")
      },
      {
        path:"app",
        name:"app",
        component:()=>import("@/pages/system/app.vue")
      },
      {
        path:"data",
        name:"data",
        component:()=>import("@/pages/system/data.vue")
      }
    ]
  }
];

export default routes;
