import axios from "./api";
import { trimObjItemValue } from "@/utils/util"; //去除空格
import toast from "@/components/toast/index";

// get参数编码  将传入的参数编码为url中的查询字符串，formatter url
function encodeURIParams(config) {
  let url = config.url;
  url += "?";
  //   遍历config.params键值对
  if (config.params) {
    const keys = Object.keys(config.params);
    for (const key of keys) {
      url += `${key}=${encodeURIComponent(config.params[key])}&`;
    }
  }
  url = url.substring(0, url.length - 1);
  config.params = {};
  return url; //https://example.com ? name=John & age=25
}

// 请求方法
const Methods = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
  patch: "patch",
};

const request = (options) => {
  options.method = options.method || Methods.get; // 默认为 get 请求

  let key = "data";
  if (options.method === Methods.get || options.method === Methods.delete) {
    key = "params";
    options.url = encodeURIParams(options); // 格式化 URL
    // 避免在 IE 中缓存相同的请求
    if (options.url.includes("?")) {
      options.url += `&t=${Date.now()}`;
    } else {
      options.url += `?t=${Date.now()}`;
    }
  }

  // 初始化 headers 属性
  options.headers = options.headers || {};

  const axiosConfig = {
    url: options.url,
    method: options.method,
    headers: options.headers, // 使用初始化的 headers
  };

  if (options.params instanceof FormData) {
    // 如果参数是 FormData，则不需要处理，直接传递
    axiosConfig[key] = options.params;
  } else {
    // 如果参数不是 FormData，处理参数
    if (options.params) {
      axiosConfig[key] = trimObjItemValue(options.params);
    }
  }

  // 如果需要发送 FormData 请求，设置请求头
  if (options.formData) {
    axiosConfig.headers["Content-Type"] = "multipart/form-data";
  }

  return axios(axiosConfig)
    .then((response) => {
      const { data } = response;
      if (data) {
        const { code } = data;
        if (code && code !== 200) {
          toast("", code);
          return Promise.reject(response);
        }
      }
      return response.data;
    })
    .catch((error) => {
      const response = error.response;
      if (response) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("orgId");
          window.location.href = "/";
        }
      }
      return Promise.reject(error.data)
    });
};

const Http = {
  login(params) {
    return request({
      url: `/system/user/login`,
      method: Methods.post,
      params,
    });
  },
  logout() {
    return request({
      url: `/system/user/logout`,
      method: Methods.get,
    });
  },
  getFirmList(params) {
    return request({
      url: `/system/attachment/list`,
      method: Methods.post,
      params,
    });
  },
  releaseFimware(params) {
    return request({
      url: `/system/attachment/publish`,
      method: Methods.post,
      params,
    });
  },
  //发布模式修改
  editReleaseMode(params) {
    return request({
      url: `/system/attachment/editMode`,
      method: Methods.post,
      params,
    });
  },
  firmwareUpload(formData) {
    return request({
      url: `/system/attachment/upload`,
      method: Methods.post,
      params: formData,
      formData: true, // 设置为 true 以指示这是一个 multipart/form-data 请求
    });
  },
  deprecateFirmware(id) {
    return request({
      url: `/system/attachment/deprecate/${id}`,
      method: Methods.get,
    });
  },
  getSensorList(params) {
    return request({
      url: `/system/sensor/list`,
      method: Methods.post,
      params,
    });
  },
  // 查询扩展
  getSensorExtended(sensorId) {
    return request({
      url: `/system/sensor/${sensorId}/extended`,
      method: Methods.get,
    });
  },
  // 修改扩展
  editSensorExtended(params) {
    return request({
      url: `/system/sensor/extended`,
      method: Methods.put,
      params
    });
  },
  getLockList(params) {
    return request({
      url: `/system/sensor/lock/list`,
      method: Methods.post,
      params,
    });
  },
  unlockSensor(params) {
    return request({
      url: `/system/sensor/batchUnlock`,
      method: Methods.post,
      params,
    });
  },
  getLogList(params) {
    return request({
      url: `/system/opLog/pageList`,
      method: Methods.post,
      params,
    });
  },
  getOrgList(pageNo, pageSize, qurey) {
    return request({
      url: `/system/org/list?pageNo=${pageNo}&pageSize=${pageSize}&query=${qurey}`,
      method: Methods.get,
    });
  },
  createOrgUser(params) {
    return request({
      url: `/system/user`,
      method: Methods.post,
      params,
    });
  },
  getRoleList() {
    return request({
      url: `/system/role/dropList`,
      method: Methods.get,
    });
  },
  // 获取楼栋层级组织列表
  getOrgBuildingList(pageNo, pageSize, qurey) {
    return request({
      url: `/system/org/building/list?pageNo=${pageNo}&pageSize=${pageSize}&query=${qurey}`,
      method: Methods.get,
    });
  },
  creareAccount(params) {
    return request({
      url: `/system/user/createAccount`,
      method: Methods.post,
      params,
    });
  },
  AddSensor(params) {
    return request({
      url: `/system/sensor/prefabricated`,
      method: Methods.post,
      params,
    });
  },
  getVersionHistory(pageNo,pageSize){
    return request({
      url:`/system/upgrade/list?pageNo=${pageNo}&pageSize=${pageSize}`,
      method:Methods.get
    })
  },
  // 发布升级预告通知
  previewUpgrade(date, version) {
    return request({
      url: `/system/upgrade/upgrade?date=${date}&version=${version}`,
      method: Methods.get,
    });
  },
  // 发布确认升级通知
  confirmUpgrade() {
    return request({
      url: `/system/upgrade/sure`,
      method: Methods.get,
    });
  },
  // 升级成功
  upgradeSuccess() {
    return request({
      url: `/system/upgrade/upgradeSuccess`,
      method: Methods.get,
    });
  },
  // 获取app版本记录
  getAppVersionList(pageNo, pageSize, query) {
    return request({
      url: `/system/appUpgradeLog/list?pageNo=${pageNo}&pageSize=${pageSize}&query=${query}`,
      method: Methods.get,
    });
  },

  releaseVersion(version, type, incompatible, remark) {
    return request({
      url: `/system/upgrade/appUpgrade?version=${version}&phoneOs=${type}&incompatible=${incompatible}&remark=${remark}`,
      method: Methods.get,
    });
  },
  getDictionary() {
    return request({
      url: `/system/base-static-data/all`,
      method: Methods.get,
    });
  },
  searchDictionary(code) {
    return request({
      url: `/system/base-static-data/getByCode/${code}`,
      method: Methods.get,
    });
  },

  getUserList(pageNo,pageSize,query){
    return request({
      url:`/system/user/pageList?pageNo=${pageNo}&pageSize=${pageSize}&query=${query}`,
      method:Methods.get,
    })
  },
  changePwd(params){
    return request({
      url: `/system/user/password`,
      method: Methods.put,
      params,
    });
  },
  //获取固件类型/平台/tasktype
  getBaseData(code) {
    return request({
      url: `/system/base-static-data/getByCode/${code}`,
      method: Methods.get,
    });
  },
  getServeList(language) {
    return request({
      url: `/system/server/system/list?language=${language}`,
      method: Methods.get,
    });
  },
  getDownload(endTime, isTp, roomId, startTime) {
    return request({
      url: `/system/rdData/export?endTime=${endTime}&isTp=${isTp}&roomId=${roomId}&startTime=${startTime}`,
      method: Methods.get,
      responseType: "blob",
    });
  },
};

Http.install = function (_Vue) {
  _Vue.prototype.$http = Http;
};
export default Http;
