<template>
  <transition name="toast">
    <div class="toast__wrapper">
      <div class="title">
        <div class="title-text">Error</div>
        <div class="title-close" @click="close"></div>
      </div>
      <div class="toast-tips">
        <div class="text">
          {{ text }}
        </div>
        <div class="code" v-if="code">Error code: {{ code }}</div>
      </div>
      <div class="line"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      text: '',
      code: '',
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    close() {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>
<style lang="scss" scoped>
.toast__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 700px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
  margin-top: 20px;

  >.title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >.title-text {
      position: relative;
      font-size: 16px;
      font-weight: 500;
      color: #222e61;
    }

    >.title-text::after {
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 100%;
      top: 50%;
      right: -28px;
      transform: translateY(-50%);
      background: url("../../assets/icon/ic-warning.png") no-repeat center;
      background-size: contain;

    }

    >.title-close {
      width: 18px;
      height: 18px;
      background: url("../../assets/icon/ic-cancel.png") no-repeat center;
      background-size: contain;
      cursor: pointer;
    }

  }

  >.toast-tips {
    font-size: 14px;
    color: #222e61;
    margin-top: 5px;
  }

  >.line {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #ff2c50;
  }

}
</style>
