import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { beforeRouterEach } from "./utils";

// 取消相同路由的报错
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
}

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach(beforeRouterEach);
export default router;
